<template>
  <ValidationObserver
      v-slot="{ handleSubmit }"
      ref="customerCreateForm"
  >
    <form
        @submit.prevent="handleSubmit(createCustomer)"
    >
      <div class="row">
        <div class="col-md-4">
          <TextInput
              v-model="business.business_name"
              name="business_name"
              rules="required"
              label="Business Name"
          />
        </div>
        <div class="col-md-4">
          <TextInput
              v-model="business.address"
              name="business_address"
              rules="required"
              label="Address"
          />
        </div>
        <div class="col-md-4">
          <TextInput
              v-model="business.city"
              name="business_city"
              rules="required"
              label="City"
          />
        </div>

        <div class="col-md-4">
          <TextInput
              v-model="business.state"
              name="business_state"
              rules="required"
              label="State"
          />
        </div>
        <div class="col-md-4">
          <TextInput
              v-model="business.zip"
              name="business_zip"
              rules="required"
              label="Zip"
          />
        </div>
        <div class="col-md-4">
          <TextInput
              v-model="business.country"
              name="business_country"
              rules="required"
              label="Country"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Phone Number</label>
          <VuePhoneNumberInput v-model="business.phone_number" valid-color="#e2e7f1" class="mb-3" />
        </div>
        <div class="col-md-6">
          <TextInput
              v-model="business.business_website"
              name="business_website"
              label="Website URL"
          ></TextInput>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <TextInput
              v-model="business.first_name"
              name="first_name"
              rules="required"
              label="First Name"
          />
        </div>
        <div class="col-md-6 col-lg-4">
          <TextInput
              v-model="business.last_name"
              name="last_name"
              rules="required"
              label="Last Name"
          />
        </div>
        <div class="col-md-6 col-lg-4">
          <TextInput
              v-model="business.email"
              name="owner.email"
              rules="required|email"
              label="Email"
          />
        </div>
        <div class="col-12">
          <div v-if="user.has_re" class="d-inline-block mr-3 mb-3">
            <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
              <span v-if="business.is_reply_engine">Enabled</span>
              <span v-else>Disabled</span>
            </small>
            <div class="d-inline-flex">
              <span class="mr-2 d-flex font-weight-bold">ReplyEngine</span>
              <b-form-checkbox
                  v-model="business.is_reply_engine"
                  size="sm"
                  name="initial-button"
                  switch
                  inline
              >
              </b-form-checkbox>
            </div>
          </div>
          <div v-if="user.has_te" class="d-inline-block mr-3 mb-3">
            <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
              <span v-if="business.is_tap_engine">Enabled</span>
              <span v-else>Disabled</span>
            </small>
            <div class="d-inline-flex">
              <span class="mr-2 d-flex font-weight-bold">TapEngine</span>
              <b-form-checkbox
                  v-model="business.is_tap_engine"
                  size="sm"
                  name="initial-button"
                  switch
                  inline
              >
              </b-form-checkbox>
            </div>
          </div>
          <div v-if="user.has_social_fusion" class="d-inline-block mr-3 mb-3">
            <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
              <span v-if="business.is_social_fusion">Enabled</span>
              <span v-else>Disabled</span>
            </small>
            <div class="d-inline-flex">
              <span class="mr-2 d-flex font-weight-bold">SocialFusion</span>
              <b-form-checkbox
                  v-model="business.is_social_fusion"
                  size="sm"
                  name="initial-button"
                  switch
                  inline
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <b-button variant="danger" @click="close">Cancel</b-button>
        <b-button
            variant="primary"
            :disabled="loadingCreate"
            type="submit"
        >
          <b-spinner v-if="loadingCreate" small />
          <span v-else>Create</span>
        </b-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
// import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  components:{
    VuePhoneNumberInput
  },
  data() {
    return {
      business:{
        business_name:'',
        address:'',
        city:'',
        state:'',
        zip:'',
        country:'',
        business_phone:'',
        business_website:'',
        first_name:'',
        last_name:'',
        email:'',
        is_reply_engine:false,
        is_tap_engine:false,
        is_social_fusion:false
      },
      loadingCreate: false,
    }
  },
  computed:{
    user(){
      return this.$store.getters['auth/user'];
    }
  },

  mounted() {
    // this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    if(this.user && this.user.has_te){
      this.business.is_tap_engine = true;
    }
    if(this.user && this.user.has_re){
      this.business.is_reply_engine = true;
    }
    if(this.user && this.user.has_social_fusion){
      this.business.is_social_fusion = true;
    }
  },

  methods: {
    close(){
      this.$emit('close');
    },
    createCustomer() {
      this.loadingCreate = true

      this.$store
        .dispatch('business/create', this.business)
        .then((res) => {
          this.loadingCreate = false;
          if(res.success) {
            this.$router.push({name: 'agency.customers.edit', params: {id: res.data.id}});
          }
        })
        .catch((err) => {
          console.log(err);
           if (err.response.data.errors) {
            this.$refs.customerCreateForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },
  },
}
</script>
